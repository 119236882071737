<template ref="jumbotron">
  <div class="jumbotron">
    <h1 class="text-center display-4">Join us on Slack</h1>
    <a target="_blank" href="https://prairietech.slack.com">
      <img
        id="logo"
        alt="Slack logo"
        class="mx-auto"
        src="@/assets/slack.png"
      />
    </a>

    <hr class="my-4" />
    <div
      v-for="error in errors"
      :key="error"
      class="alert alert-danger mt-2"
      role="alert"
    >
      <strong>An error occurred:</strong> {{ error }}
    </div>
    <div v-if="successMessage.length > 0" class="card mt-2">
      <div class="card-body">
        <a :href="successMessage" class="card-link"
          >Click here to complete sign-up</a
        >
      </div>
    </div>
    <InviteForm
      v-if="successMessage.length < 1"
      @submitSuccess="handleSubmitSuccess"
      @submitError="handleSubmitError"
    />
  </div>
</template>

<script>
import InviteForm from "./InviteForm";
export default {
  name: "JumboTron",
  components: {
    InviteForm,
  },
  data() {
    return {
      errors: [],
      successMessage: "",
    };
  },
  methods: {
    handleSubmitSuccess(message) {
      this.errors = [];
      if (message && message.url) {
        this.successMessage = message.url;
        return
      }
      this.errors.push('Invalid server response received');
      console.log(message)
    },
    handleSubmitError(error) {
      this.errors.push(error);
    },
  },
};
</script>

<style scoped>
#logo {
  width: 200px;
  display: block;
}
</style>