<template>
      <!-- <img id="logo" alt="Slack logo" src="./assets/slack.png"> -->

  <JumboTron msg="Join us on Slack!"/>
</template>

<script>

import JumboTron from './components/JumboTron.vue'

export default {
  name: 'App',
  components: {
    JumboTron
  }
}
</script>

<style scoped>

</style>
