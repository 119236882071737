<template ref="inviteForm">
  <form v-on:submit.prevent="onSubmit">
    <div class="form-row">
      <vue-recaptcha
        class="mx-auto"
        v-show="true"
        siteKey="6LepNzweAAAAAHqZJJ-LYO5v834DpGhE2xpl75os"
        size="normal"
        theme="light"
        @verify="recaptchaVerified"
        @expire="recaptchaExpired"
        @fail="recaptchaFailed"
        ref="vueRecaptcha"
      />
    </div>
    <div class="form-row">
      <button
        @click="submit"
        :disabled="isButtonDisabled"
        type="submit"
        class="btn btn-primary btn-large mx-auto mt-2"
      >
        Get Invite Link
      </button>
    </div>
  </form>
</template>

<script>
import vueRecaptcha from "vue3-recaptcha2";

export default {
  name: "InviteForm",
  components: {
    vueRecaptcha,
  },
  data() {
    return {
      captchaResponse: "",
    };
  },
  computed: {
    isButtonDisabled() {
      return this.captchaResponse.length > 0 ? false : true;
    },
  },
  methods: {
    recaptchaVerified(response) {
      this.captchaResponse = response;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {},
    submit() {
      fetch("/.netlify/functions/invite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ captchaResponse: this.captchaResponse }),
      })
        .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          this.$emit("submitSuccess", data);
        })
        .catch((error) => {
          this.$emit("submitError", error);
        });
    },
  },
};
</script>

<style scoped>
</style>